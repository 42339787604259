import Papa from "papaparse";
import { LoadInsightsPayload } from "./insightsTypes";
import numeral from "numeral";
import moment from "moment";
import { MOMENT_DATE_FORMAT } from "index";

export const EMAIL_VALIDATION_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const parseInsightsCSV = async (
    insightsData: LoadInsightsPayload["insightsData"],
    callback: (locationData: Papa.ParseResult<any>) => void,
) => {
    Papa.parse(insightsData!.details, {
        worker: true,
        delimiter: ",",
        header: true,
        complete: (results) => {
            // Below causes downstream issues in the mantine table.
            // empty row at the end of the csv is removed.
            if (
                results.errors.length > 0 &&
                results.errors[0].code === "TooFewFields"
            ) {
                results.data.pop();
            }

            callback(results);
        },
        dynamicTyping: true,
    });
};

const DO_NOT_FORMAT_CONDITIONS = ["id", "count", "code", "contract", "year"];
const SHOW_TWO_DECIMALS_CONDITIONS = ["exchange_rate"];
const SHOW_FOUR_DECIMALS_CONDITIONS = ["share", "longitude", "latitude"];

export const formatValue = (value: any, propertyName: string) => {
    propertyName = propertyName.toLowerCase();

    if (DO_NOT_FORMAT_CONDITIONS.some((i) => propertyName.includes(i))) {
        return value;
    }

    // After filtering is applied value is an object. Extract cell value
    let cellValue = value;
    if (typeof value === "object") {
        cellValue = value.props.children;
    }

    let valToNumber = Number(cellValue);
    let isNumber = !Number.isNaN(valToNumber);

    if (propertyName.includes("date")) {
        // Some Date columns include strings, so if not a valid number do not multiply by 1000 for UNIX conversion
        let convertedValue = isNumber ? cellValue * 1000 : cellValue;
        return moment(convertedValue).format(MOMENT_DATE_FORMAT);
    }

    if (propertyName.toLowerCase().includes("time")) {
        //Regex to change time string to human readable format
        return value.replace(/(.{2})$/,':$1');
    }

    if (isNumber) {
        let numeralFormatStyle = "0,0";
        if (
            SHOW_TWO_DECIMALS_CONDITIONS.some((i) =>
                propertyName.includes(i),
            ) ||
            (valToNumber > 0 && valToNumber < 1)
        ) {
            numeralFormatStyle = "0,0.00";
        } else if (
            SHOW_FOUR_DECIMALS_CONDITIONS.some((i) => propertyName.includes(i))
        ) {
            numeralFormatStyle = "0,0.0000";
        }

        return numeral(valToNumber).format(numeralFormatStyle);
    }

    return value;
};
